.body-timonk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  /* background: #d9d7dd; */
  background: #40487a;
}

.body-timonk .clock {
  height: auto;
  width: auto;
  border-radius: 100%;
  /* background: #ffffff; */
  font-family: "Montserrat";
  /* border: 5px solid white; */
  /* box-shadow: inset 2px 3px 8px 0 rgba(0, 0, 0, 0.1); */
}

.body-timonk .wrap {
  overflow: hidden;
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 100%;
}

.body-timonk .minute,
.body-timonk .hour {
  position: absolute;
  height: 100px;
  width: 6px;
  margin: auto;
  top: -27%;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  transform-origin: bottom center;
  transform: rotate(0deg);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.body-timonk .minute {
  position: absolute;
  height: 130px;
  width: 4px;
  top: -36%;
  left: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  transform: rotate(90deg);
}

.body-timonk .second {
  position: absolute;
  height: 90px;
  width: 2px;
  margin: auto;
  top: -24%;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  background: #ff4b3e;
  transform-origin: bottom center;
  transform: rotate(180deg);
  z-index: 1;
}

.body-timonk .dot {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: white;
  border: 2px solid #1b1b1b;
  border-radius: 100px;
  margin: auto;
  z-index: 1;
}

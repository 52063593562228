@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --primary-color: #000;
  --secondary-color: #fff;
}

html {
  transition: all 0.5s ease-in;
  color: #fff;
  background-color: #000;
}

html.dark {
  --primary-color: #fff;
  --secondary-color: #333;
}

html.dark {
  /* background-color: #111; */
  color: var(--primary-color);
}

body {
  font-family: "Heebo", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.controls {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 75px;
  display: flex;
  z-index: 3;
  justify-content: space-between;
  font-size: 30px;
  color: gray;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #fff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.motion-wrap svg {
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.motion-wrap .scrollElement {
  position: absolute;
  height: 6000px;
  width: 100px;
  top: 0;
  z-index: 0;
}

.motion-wrap .btn {
  position: fixed;
  bottom: 5%;
  right: 0px;
  transform: translateX(-50%);
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 0.5rem 0.7em;
  background-color: transparent;
  color: #ffffff;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 11;
}

.motion-wrap .btn_works {
  left: 100px;
  right: unset;
  text-decoration: none;
}

.motion-wrap .btn:hover {
  background: #ffffff;
  color: #1b1734;
}

.main-time-motion {
  font-size: 100px;
  position: fixed;
  font-weight: 500;
  top: 0;
  left: 50px;
  background: linear-gradient(to right, #9cf4e6, #df2ed6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.seheri,
.iftar {
  background: linear-gradient(to right, #9cf4e6, #df2ed6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ramadhan {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  padding: 10px;
  .time {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #fff;
    justify-content: center;
    padding: 10px 18px 59px 49px;
    h1 {
      font-family: Poppins;
      font-size: 85px;
      font-weight: 300;
      margin: 0;
      margin-bottom: -25px;
    }
    .date {
      font-family: Poppins;
      font-size: 22px;
    }
    .weather-info {
      font-family: Poppins;
      font-weight: 100;
      font-size: 22px;
    }
    .place {
      margin-top: 10px;
      font-family: Poppins;
      font-weight: 100;
      font-size: 15px;
    }
  }
  .salah-times {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    background: #262727b3;
    padding-left: 46px;
    border-radius: 10px;
  }
  background-image: url(./eid-mubarak-lantern-at-night-muslim-holiday-2024-01-29-21-46-26-utc.jpg);
  background-size: cover;
  .prayer-times {
    display: flex;
    margin-bottom: 1px;
    justify-content: space-between;
  }
  .islamic-month {
    span:first-child {
      font-size: 60px;
      font-weight: 100;
      font-family: Poppins;
    }
    span:last-child {
      font-size: 30px;
      font-weight: 100;
      font-family: Poppins;
      margin-left: 10px;
    }
  }
}
